export const carrierIdentifierOptions = [
  { value: "U", text: "None" },
  { value: "F", text: "FEIN" },
  { value: "S", text: "SSN" },
];
export const delimiter = "$#$";
export const wizmoKey =
  "Honeywell Technology Solution Private Limited,345719257312529#B0ZcIRmI1pjIs9WQisnOiQkIsISP3EUUWNjSLx6Y6lXbJZDa4ZkY5VTOuVWS5EjTIx4RkNFUtZGSixWQCplcnBTZpFlNpJ6Kv44Rp5kbCJkbL9mdZN6MvskYLNUSpFHUChUb8xGdV5kazYTRq56MUFjZ8dVR75UZahDViFDSuVXQLdGcBdWTwVnNrg7TrpWS7wUdWBjdwYzRwRFbChWcUd6QPFGOCJlUiNXN5p4LVZ4LOBDU5g5Qu9UR8ZDMGRXUFlGaJp7Q0NkWiFzcwY7Sk3yV9J5QyR5Rr2CMHZ6YB9kZN9WQ5lVU5sSWJRldNJTMidkbFN4NhR6dn34L7AnR0FUbOp6NOJ5b6BzQ6VTbKNEbiZXZk5Wd5FTWDpWZBV4avVEZ4MXanBjYTdXOSNnUUpnW5gWWFZnZMd6NXx4SvRXRpxWQVxmRTh6dtBFSqpnNIJTdtlWMrwmRrYDaVdTbxlmeM34RrMDbJZjRnJiOiMlIsISR6MTN4EjMiojIIJCL6gTN7MzNxITM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsIiN5IDMyADI6ADNwEjMwIjI0ICdyNkIsICZlRXatlGTgUGdhZXayBFIu3Wa4VHbvNFI9d6bs3mboNWZUBCbsV6d9VmbvhkI0ISYONkIsISOyUjMxMzN5ITOxcTN4MjI0ICZJJCL3JSM6FjMwIjI0IiclZnIsU6csF7ZMI";
export const FileMode = {
  ADD: "ADD",
  EDIT: "EDIT",
  DELETE: "DELETE",
};
export const HSEInspectionStatus = {
  NOT_DONE: 0,
  PASS: 1,
  FAIL: 2,
  INPROGRESS: 3,
};

export const filteredOptionsCount = 100;
export const TransportationType = {
  ROAD: "ROAD",
  RAIL: "RAIL",
  MARINE: "MARINE",
  PIPELINE: "PIPELINE",
};
export const TrailerType = {
  RIGID_TRAILER: "RIGID_TRAILER",
  NON_RIGID_TRAILER: "NON_RIGID_TRAILER",
  SHIP: "SHIP",
  RAILWAGON: "RAILWAGON",
  BARGE: "BARGE",
};

export const VehicleType = {
  RigidTruck: "RIGID_TRUCK",
  RigidTruckWithTrailer: "RIGID_TRUCK_WITH_TRAILER",
  TractorWithTrailer: "TRACTOR_WITH_TRAILER",
  NonFillingVehicle: "NON_FILLING_VEHICLE",
  Ship: "SHIP",
  Barge: "BARGE",
};

export const orderType = { PLANNED: 0, CONTRACT: 1 };
export const contractType = { DEFAULT_CONTRACT_TYPE: 0 };

export const contractStatus = {
  CREATED: 0,
  SCHEDULED: 1,
  CLOSED: 2,
  EXPIRED: 3,
};

export const orderStatus = {
  NOT_SCHEDULED: 0,
  PARTIALLY_SCHEDULED: 1,
  FULLY_SCHEDULED: 2,
  PARTIALLY_DELIVERED: 3,
  FULLY_DELIVERED: 4,
  CLOSED: 5,
  NOT_DELIVERED: 6,
  READY: 7,
  EXPIRED: 8,
  CANCELLED: 9,
  REJECTED: 10,
};

export const ShipmentStatus = {
  AUTO_LOADED: 0,
  CHECKED_IN: 1,
  CLOSED: 2,
  INTERRUPTED: 3,
  LOADING: 4,
  MANUALLY_LOADED: 5,
  PARTIALLY_LOADED: 6,
  QUEUED: 7,
  READY: 8,
  DE_QUEUED: 9,
  WEIGHED_IN: 10,
  WEIGHED_OUT: 11,
  USER_DEFINED: 12,
  ASSIGNED: 13,
  CANCELLED: 14,
  EXPIRED: 15,
  REJECTED: 16,
};

export const ViewDispatchStatus = {
  AUTO_LOADED: 0,
  CLOSED: 1,
  INTERRUPTED: 2,
  LOADING: 3,
  MANUALLY_LOADED: 4,
  PARTIALLY_LOADED: 5,
  QUEUED: 6,
  READY: 7,
};

export const ViewDispatch_Status = {
  AUTO_LOADED: "AUTO_LOADED",
  CLOSED: "CLOSED",
  INTERRUPTED: "INTERRUPTED",
  LOADING: "LOADING",
  MANUALLY_LOADED: "MANUALLY_LOADED",
  PARTIALLY_LOADED: "PARTIALLY_LOADED",
  QUEUED: "QUEUED",
  READY: "READY",
};

export const Shipment_Status = {
  AUTO_LOADED: "AUTO_LOADED",
  CHECKED_IN: "CHECKED_IN",
  CLOSED: "CLOSED",
  INTERRUPTED: "INTERRUPTED",
  LOADING: "LOADING",
  MANUALLY_LOADED: "MANUALLY_LOADED",
  PARTIALLY_LOADED: "PARTIALLY_LOADED",
  QUEUED: "QUEUED",
  READY: "READY",
  DE_QUEUED: "DE_QUEUED",
  WEIGHED_IN: "WEIGHED_IN",
  WEIGHED_OUT: "WEIGHED_OUT",
  USER_DEFINED: "USER_DEFINED",
  ASSIGNED: "ASSIGNED",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
  REJECTED: "REJECTED",
};
export const ShipmentCompartmentStatus = {
  EMPTY: 0,
  LOADING: 1,
  PART_FILLED: 2,
  OVER_FILLED: 3,
  FORCE_COMPLETED: 4,
  COMPLETED: 5,
  INTERRUPTED: 6,
};
export const deviceTypeCode = {
  BCU: 0,
  DEU: 1,
  CARD_READER: 2,
  WEIGH_BRIDGE: 3,
  ATG: 4,
  MULTIPLEBCU: 5,
  PIPELINE: 6,
};
export const deviceTypes = {
  BCU: "BCU",
  DEU: "DEU",
  CARD_READER: "CARD_READER",
  WEIGH_BRIDGE: "WEIGH_BRIDGE",
  ATG: "ATG",
  MULTIPLEBCU: "MULTIPLEBCU",
  PIPELINE: "PIPELINE",
}

export const EmailPriority = [
  {
    text: "High",
    value: 2,
  },
  {
    text: "Medium",
    value: 0,
  },
  {
    text: "Low",
    value: 1,
  },
];
export const DataType = {
  INT: "Int",
  STRING: "String",
  FLOAT: "Float",
  DOUBLE: "Double",
  LONG: "Long",
  BOOL: "Bool",
  DATETIME: "DateTime",
};

export const IsTransloading = {
  LOADING: "LOADING",
  NONE: "NONE",
};

export const utcTimeZones = [
  { text: "UTC-12:00", value: -720 },
  { text: "UTC-11:00", value: -660 },
  { text: "UTC-10:00", value: -600 },
  { text: "UTC-9:30", value: -570 },
  { text: "UTC-9:00", value: -540 },
  { text: "UTC-8:00", value: -480 },
  { text: "UTC-6:00", value: -360 },
  { text: "UTC-5:00", value: -300 },
  { text: "UTC-4:00", value: -240 },
  { text: "UTC-3:30", value: -210 },
  { text: "UTC-3:00", value: -180 },
  { text: "UTC-2:00", value: -120 },
  { text: "UTC-1:00", value: -60 },
  { text: "UTC+0:00", value: 0 },
  { text: "UTC+1:00", value: 60 },
  { text: "UTC+2:00", value: 120 },
  { text: "UTC+3:00", value: 180 },
  { text: "UTC+3:30", value: 210 },
  { text: "UTC+4:00", value: 240 },
  { text: "UTC+4:30", value: 270 },
  { text: "UTC+5:00", value: 300 },
  { text: "UTC+5:30", value: 330 },
  { text: "UTC+5:45", value: 345 },
  { text: "UTC+6:00", value: 360 },
  { text: "UTC+6:30", value: 390 },
  { text: "UTC+7:00", value: 420 },
  { text: "UTC+8:00", value: 480 },
  { text: "UTC+8:45", value: 525 },
  { text: "UTC+9:00", value: 540 },
  { text: "UTC+9:30", value: 570 },
  { text: "UTC+10:00", value: 600 },
  { text: "UTC+10:30", value: 630 },
  { text: "UTC+11:00", value: 660 },
  { text: "UTC+12:00", value: 720 },
  { text: "UTC+12:45", value: 765 },
  { text: "UTC+13:00", value: 780 },
  { text: "UTC+14:00", value: 840 },
];

export const shipmentTypeOptions = { All: 0, SBC: 1, SBP: 2 };
export const shipmentFrom = { None: 0, Order: 1, Contract: 2, All: 3 };
export const slotSource = {
  SHIPMENT: "1",
  RECEIPT: "2",
  ORDER: "3",
  CONTRACT: "4",
};
export const shipmentType = { COMPARTMENT: "COMPARTMENT", PRODUCT: "PRODUCT" };
export const PrimeMoverType = {
  RIGID_PRIMEMOVER: "RIGID_PRIMEMOVER",
  NON_RIGID_PRIMEMOVER: "NON_RIGID_PRIMEMOVER",
};
export const siteViewLocationType = {
  ENTRYGATE: "EntryGate",
  REPORTINGOFFICE: "ReportingOffice",
  GANTRY: "GANTRY",
  BOLOFFICE: "BOLOffice",
  EXITGATE: "ExitGate",
  BAY: "Bay",
  ISLAND: "ISLAND",
  BERTH: "MarineBay",
  RAILENTRYGATE: "RailEntryGate",
  RAILEXITGATE: "RailExitGate",
  CLUSTER: "Cluster",
  SPUR: "SPUR",
};

export const LoadingArmConstants = {
  MSC_L: "MSC-L",
  RATIO: "RATIO",
  RATIO_SEQUENTIAL: "RATIO/SEQUENTIAL",
};

export const siteViewType = {
  ROAD_SITEVIEW: "ROAD_SITEVIEW",
  ROAD_BCUVIEW: "ROAD_BCUVIEW",
  MARINE_SITEVIEW: "MARINE_SITEVIEW",
  MARINE_BCUVIEW: "MARINE_BCUVIEW",
  RAIL_SITEVIEW: "RAIL_SITEVIEW",
  RAIL_BCUVIEW: "RAIL_BCUVIEW",
};

export const hideShowContent = {
  HIDE_ATTRIBUTES: "HIDE ATTRIBUTES",
  SHOW_ATTRIBUTES: "SHOW ATTRIBUTES",
};

export const meterLineType = {
  MAINLINE: "MAINLINE",
  ADDITIVE: "ADDITIVE",
  PIPELINE: "PIPELINE"
};

export const TMUIInstallType = {
  LIVE: "TMUI",
  ARCHIVE: "TMUIArchive",
};

export const meterTypeConstants = {
  BASE_PRODUCT_METER: "BASE_PRODUCT_METER",
  ADDITIVE_METER: "ADDITIVE_METER",
  PIPELINE_HEADER_METER: "PIPELINE_HEADER_METER"
};
export const slotStatus = { BOOKED: 0, CANCELED: 1, COMPLETED: 2 };

export const TransloadSource = [
  { text: "MARINE", value: "MARINE" },
  { text: "RAIL", value: "RAIL" },
];

export const Identification = [{ text: "Manual", value: "Manual" }];

export const associatedBayOptions = [
  { text: "BOTH", value: 0 },
  { text: "1", value: 1 },
  { text: "2", value: 2 },
];

// export const ReceiptCompartmentStatus = {
//   COMPLETED: "COMPLETED",
//   EMPTY: "EMPTY",
//   FORCE_COMPLETED: "FORCE_COMPLETED",
//   INTERRUPTED: "INTERRUPTED",
//   OVER_UNLOADED: "OVER_UNLOADED",
//   PART_UNLOADED: "PART_UNLOADED",
//   UNLOAD_NOTSTARTED: "UNLOAD_NOTSTARTED",
//   UNLOADING: "UNLOADING",
// };

export const ViewAllReceiptOperations = {
  ViewReceipt_AuthorizeToUnload: "ViewMarineReceiptList_AuthorizeToUnload",
  ViewReceipt_CloseReceipt: "ViewMarineReceiptList_CloseReceipt",
  ViewReceipt_PrintRAN: "ViewMarineReceiptList_PrintRAN",
  ViewReceipt_PrintBOD: "ViewMarineReceiptList_PrintBOD",
  ViewReceipt_ViewBOD: "ViewMarineReceiptList_ViewBOD",
  ViewReceipt_BISOutbound: "ViewShipment_BSIOutbound",
  ViewReceipt_ViewAuditTrail: "ViewMarineReceiptList_ViewAuditTrail",
  ViewReceipt_ViewTransactions: "ViewMarineReceiptList_ViewTransactions",
  ViewReceipt_ManualEntry: "ViewMarineReceiptList_ManualEntry",
};

export const UOM = {
  QuantityUOM: "QuantityUOM",
  DensityUOM: "DensityUOM",
  TemperatureUOM: "TemperatureUOM",
  MassUOM: "MassUOM",
  PressureUOM: "PressureUOM",
  CalculatedValueUOM: "CalculatedValueUOM",
};
export const ReceiptStatus = {
  AUTO_LOADED: 0,
  AUTO_UNLOADED: 0,
  CHECKED_IN: 1,
  CLOSED: 2,
  INTERRUPTED: 3,
  UNLOADING: 4,
  MANUALLY_UNLOADED: 5,
  PARTIALLY_UNLOADED: 6,
  QUEUED: 7,
  READY: 8,
  DE_QUEUED: 9,
  WEIGHED_IN: 10,
  WEIGHED_OUT: 11,
  USER_DEFINED: 12,
  ASSIGNED: 13,
  CANCELLED: 14,
  EXPIRED: 15,
  REJECTED: 16,
};

export const Receipt_Status = {
  AUTO_UNLOADED: "AUTO_UNLOADED",
  CHECKED_IN: "CHECKED_IN",
  CLOSED: "CLOSED",
  INTERRUPTED: "INTERRUPTED",
  UNLOADING: "UNLOADING",
  MANUALLY_UNLOADED: "MANUALLY_UNLOADED",
  PARTIALLY_UNLOADED: "PARTIALLY_UNLOADED",
  QUEUED: "QUEUED",
  READY: "READY",
  DE_QUEUED: "DE_QUEUED",
  WEIGHED_IN: "WEIGHED_IN",
  WEIGHED_OUT: "WEIGHED_OUT",
  USER_DEFINED: "USER_DEFINED",
  ASSIGNED: "ASSIGNED",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
  REJECTED: "REJECTED",
};
export const ViewReceiptStatus = {
  AUTO_UNLOADED: 0,
  CLOSED: 1,
  INTERRUPTED: 2,
  UNLOADING: 3,
  MANUALLY_UNLOADED: 4,
  PARTIALLY_UNLOADED: 5,
  QUEUED: 6,
  READY: 7,
};

export const ViewReceipt_Status = {
  AUTO_UNLOADED: "AUTO_UNLOADED",
  CLOSED: "CLOSED",
  INTERRUPTED: "INTERRUPTED",
  UNLOADING: "UNLOADING",
  MANUALLY_UNLOADED: "MANUALLY_UNLOADED",
  PARTIALLY_UNLOADED: "PARTIALLY_UNLOADED",
  QUEUED: "QUEUED",
  READY: "READY",
};

export const MarineDispatchCompartmentDetailType = {
  EMPTY: 0,
  LOADING: 1,
  PART_FILLED: 2,
  OVER_FILLED: 3,
  FORCE_COMPLETED: 4,
  COMPLETED: 5,
  INTERRUPTED: 6,
};
export const ViewAllMarineShipmentOperations = {
  ViewMarineShipmentList_AuthorizeToLoad:
    "ViewMarineShipmentList_AuthorizeToLoad",
  ViewMarineShipmentList_CloseMarineShipment:
    "ViewMarineShipmentList_CloseMarineShipment",
  ViewMarineShipmentList_ViewAuditTrail:
    "ViewMarineShipmentList_ViewAuditTrail",
  ViewMarineShipmentList_ViewTransactions:
    "ViewMarineShipmentList_ViewTransactions",
  ViewMarineShipmentList_PrintFAN: "ViewMarineShipmentList_PrintFAN",
  ViewMarineShipmentList_ViewBOL: "ViewMarineShipmentList_ViewBOL",
  ViewMarineShipmentList_PrintBOL: "ViewMarineShipmentList_PrintBOL",
  ViewMarineShipmentList_BSIOutbound: "ViewShipment_BSIOutbound",
  ViewShipment_ForceComplete: "ViewShipment_ForceComplete",
  ViewShipment_AdjustmentToPlannedQuantity:
    "ViewShipment_AdjustmentToPlannedQuantity",
  ViewMarineShipmentList_ManualEntry: "ViewMarineShipmentList_ManualEntry",
};
export const MarineDispatchCompartmentStatus = {
  EMPTY: 0,
  LOADING: 1,
  PART_FILLED: 2,
  OVER_FILLED: 3,
  FORCE_COMPLETED: 4,
  COMPLETED: 5,
  INTERRUPTED: 6,
};

export const CommonEntityType = {
  TMUser: "TMUser",
  Staff: "Staff",
  Visitor: "Visitor",
};

export const CardStatus = {
  AVAILABLE: "AVAILABLE",
  ISSUED: "ISSUED",
  ACTIVATED: "ACTIVATED",
  EXPIRED: "EXPIRED",
};

export const ViewAllShipmentOperations = {
  ViewShipment_AuthorizeLoad: "ViewShipment_AuthorizeLoad",
  ViewShipment_AllowToLoad: "ViewShipment_AllowToLoad",
  ManualEntry: "ManualEntry",
  ViewShipment_RecordWeight: "ViewShipment_RecordWeight",
  ViewShipment_CloseShipment: "ViewShipment_CloseShipment",
  ViewShipmentStatus_PrintFAN: "ViewShipmentStatus_PrintFAN",
  ViewShipmentStatus_PrintBOL: "ViewShipmentStatus_PrintBOL",
  ViewShipmentStatus_ViewBOL: "ViewShipmentStatus_ViewBOL",
  ViewAllShipment_SendBOL: "ViewAllShipment_SendBOL",
  ViewAllShipment_BSIOutbound: "ViewAllShipment_BSIOutbound",
  ViewShipment_ViewAuditTrail: "ViewShipment_ViewAuditTrail",
  ViewShipment_ViewLoadingDetails: "ViewShipment_ViewLoadingDetails",
  ViewAllShipment_SealCompartments: "ViewAllShipment_SealCompartments",
};

export const ProductReconciliationReportStatus = {
  Open: "Open",
  Closed: "Closed",
  In_Progress: "In_Progress",
  Approved: "Approved",
  Reconciled_Diff: "Reconciled_Diff",
  Reconciled_NoDiff: "Reconciled_NoDiff",
  Rejected: "Rejected",
  Cancelled: "Cancelled",
};

export const LoadingDetailsType = {
  AUTOMATIC: 0,
  MANUAL: 1,
  TOPUP: 2,
  DECANT: 3,
};
export const AssociationPlanType = {
  COMPARTMENT: "COMPARTMENT",
  PRODUCT: "PRODUCT",
};
export const ReceiptCompartmentStatus = {
  COMPLETED: 0,
  EMPTY: 1,
  FORCE_COMPLETED: 2,
  INTERRUPTED: 3,
  OVER_UNLOADED: 4,
  PART_UNLOADED: 5,
  UNLOAD_NOTSTARTED: 6,
  UNLOADING: 7,
};
export const ReceiptCompartment_Status = {
  COMPLETED: "COMPLETED",
  EMPTY: "EMPTY",
  FORCE_COMPLETED: "FORCE_COMPLETED",
  INTERRUPTED: "INTERRUPTED",
  OVER_UNLOADED: "OVER_UNLOADED",
  PART_UNLOADED: "PART_UNLOADED",
  UNLOAD_NOTSTARTED: "UNLOAD_NOTSTARTED",
  UNLOADING: "UNLOADING",
};
export const ViewAllRailReceiptOperations = {
  ViewRailReceipt_AuthorizeToUnload: "ViewRailReceipt_AuthorizeToUnLoad",
  ViewRailReceipt_RecordWeight: "ViewRailReceipt_RecordWeight",
  ViewRailReceipt_CloseReceipt: "ViewRailReceipt_CloseReceipt",
  ViewRailReceipt_ViewAuditTrail: "ViewRailReceipt_ViewAuditTrail",
  ViewRailReceipt_ViewLoadingDetails: "ViewRailReceipt_ViewLoadingDetails",
  ViewRailReceipt_PrintRAN: "ViewRailReceipt_PrintRAN",
  ViewRailReceipt_ViewBOD: "ViewRailReceipt_ViewBOD",
  ViewRailReceipt_PrintBOD: "ViewRailReceipt_PrintBOD",
  ViewRailReceipt_BISOutbound: "ViewRailReceipt_BSIOutbound",
  ViewRailReceipt_ManualEntry: "ViewRailReceipt_ManualEntry",
  ViewRailReceipt_LoadSpotAssign: "ViewRailReceipt_LoadSpotAssign",
};

export const Dispatch_Status = {
  AUTO_LOADED: "AUTO_LOADED",
  CHECKED_IN: "CHECKED_IN",
  CLOSED: "CLOSED",
  INTERRUPTED: "INTERRUPTED",
  UNLOADING: "UNLOADING",
  MANUALLY_UNLOADED: "MANUALLY_UNLOADED",
  PARTIALLY_UNLOADED: "PARTIALLY_UNLOADED",
  QUEUED: "QUEUED",
  READY: "READY",
  DE_QUEUED: "DE_QUEUED",
  WEIGHED_IN: "WEIGHED_IN",
  WEIGHED_OUT: "WEIGHED_OUT",
  USER_DEFINED: "USER_DEFINED",
  ASSIGNED: "ASSIGNED",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
  REJECTED: "REJECTED",
};

export const AccessCardEntityType = {
  PrimeMover: "PrimeMover",
  RailWagon: "RailWagon",
  Trailer: "Trailer",
  Vehicle: "Vehicle",
};

export const HSEInpectionStatus = {
  NOT_DONE: 0,
  PASS: 1,
  FAIL: 2,
  INPROGRESS: 3,
};

export const ViewAllShipmentFields = {
  BAY: "BayCode",
  LOADINGARM: "LoadingArmCode",
  BCU: "BCUCode",
  CompartmentSeqNoInVehicle: "CompartmentSeqNoInVehicle",
  FP: "FP",
  Common: "Common",
  BP: "BP",
  Adv: "Additive",
  RailTransload: "RailTransload",
  MarineTransload: "MarineTransload",
};

export const TopUpDecantApprovalStatus = {
  NONE: "NA",
  REQUEST_APPROVE: "APPROVE",
  REQUEST_REJECT: "REJECT",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  SUBMITTED: "SUBMITTED",
  COMPLETED: "COMPLETED",
  FORCE_COMPLETED: "FORCE_COMPLETED",
  AUTO_APPROVED: "AUTO_APPROVED",
};

export const ExtendEntity = {
  DRIVER: "DRIVER",
  VEHICLE: "VEHICLE",
  SHIPMENT: "SHIPMENT",
  SHIPMENTDESTINATIONCOMPARTMENT: "SHIPMENTDESTINATIONCOMPARTMENT",
  SHIPMENTDETAILS: "SHIPMENTDETAILS",
  SHIPMENTTRAILERWEIGHBRIDGE: "SHIPMENTTRAILERWEIGHBRIDGE",
  SHIPMENTCOMPARTMENT: "SHIPMENTCOMPARTMENT",
  SHIPMENTTRAILER: "SHIPMENTTRAILER",
  SHIPMENTSTATUSTIME: "SHIPMENTSTATUSTIME",
  TANK: "TANK",
  PRIMEMOVER: "PRIMEMOVER",
  TRAILER: "TRAILER",
  CUSTOMER: "CUSTOMER",
  DESTINATION: "DESTINATION",
  LOADINGARM: "LOADINGARM",
  SHAREHOLDER: "SHAREHOLDER",
  CARRIER: "CARRIER",
  FINISHEDPRODUCT: "FINISHEDPRODUCT",
  BASEPRODUCT: "BASEPRODUCT",
  VEHICLETRAILER: "VEHICLETRAILER",
  VEHICLEPRIMEMOVER: "VEHICLEPRIMEMOVER",
  TRAILERCOMPARTMENT: "TRAILERCOMPARTMENT",
  LOADINGDETAILS: "LOADINGDETAILS",
  ACCESSCARD: "ACCESSCARD",
  BCU: "BCU",
  METER: "METER",
  TERMINALLAYOUT: "TERMINALLAYOUT",
  ADDITIVEMETER: "ADDITIVEMETER",
  BAY: "BAY",
  CARDREADER: "CARDREADER",
  WEIGHBRIDGE: "WEIGHBRIDGE",
  DEU: "DEU",
  LOADINGDETAILSFP: "LOADINGDETAILSFP",
  LOADINGDETAILSBP: "LOADINGDETAILSBP",
  LOADINGDETAILSADDITIVE: "LOADINGDETAILSADDITIVE",
  LOADINGDETAILSLOCAL: "LOADINGDETAILSLOCAL",
  LEAKAGEDETAILS: "LEAKAGEDETAILS",
  ORDER: "ORDER",
  ORDERITEM: "ORDERITEM",
  CONTRACT: "CONTRACT",
  CONTRACT_ITEM: "CONTRACT_ITEM",
  PROCESSCONFIG: "PROCESSCONFIG",
  PRODUCTALLOCATIONITEM: "PRODUCTALLOCATIONITEM",
  RECEIPT: "RECEIPT",
  RECEIPTORIGINTERMINALCOMPARTMENT: "RECEIPTORIGINTERMINALCOMPARTMENT",
  RECEIPTTRAILERWEIGHBRIDGE: "RECEIPTTRAILERWEIGHBRIDGE",
  RECEIPTCOMPARTMENT: "RECEIPTCOMPARTMENT",
  RECEIPTTRAILER: "RECEIPTTRAILER",
  RECEIPTSTATUSTIME: "RECEIPTSTATUSTIME",
  UNACCOUNTEDTANKTRANSACTION: "UNACCOUNTEDTANKTRANSACTION",
  UNACCOUNTEDMETERTRANSACTION: "UNACCOUNTEDMETERTRANSACTION",
  ORIGINTERMINAL: "ORIGINTERMINAL",
  SUPPLIER: "SUPPLIER",
  UNLOADINGTRANSACTIONS: "UNLOADINGTRANSACTIONS",
  PIPELINEHEADER: "PIPELINEHEADER",
  PIPELINEDISPATCH: "PIPELINEDISPATCH",
  PIPELINERECEIPT: "PIPELINERECEIPT",
  CAPTAIN: "CAPTAIN",
  MARINE_VESSEL: "MARINE_VESSEL",
  MARINE_TRAILERCOMPARTMENT: "MARINE_TRAILERCOMPARTMENT",
  GENERALTMUSER: "GENERALTMUSER",
  GENERALTMUSER_CAPTAIN: "GENERALTMUSER_CAPTAIN",
  GENERALTMUSER_STAFF: "GENERALTMUSER_STAFF",
  GENERALTMUSER_VISITOR: "GENERALTMUSER_VISITOR",
  PIPELINETRANSACTIONS: "PIPELINETRANSACTIONS",
  MARINEDISPATCH: "MARINEDISPATCH",
  MARINEDISPATCHCOMPARTMENT: "MARINEDISPATCHCOMPARTMENT",
  MARINEDISPATCHCOMPARTMENTDETAIL: "MARINEDISPATCHCOMPARTMENTDETAIL",
  MARINEDISPATCHSTATUSTIME: "MARINEDISPATCHSTATUSTIME",
  MARINEDISPATCHITEM: "MARINEDISPATCHITEM",
  MARINELOADINGDETAILSFP: "MARINELOADINGDETAILSFP",
  MARINELOADINGDETAILSBP: "MARINELOADINGDETAILSBP",
  MARINELOADINGDETAILSADDITIVE: "MARINELOADINGDETAILSADDITIVE",
  MARINEUNLOADINGDETAILSFP: "MARINEUNLOADINGDETAILSFP",
  MARINEUNLOADINGDETAILSBP: "MARINEUNLOADINGDETAILSBP",
  MARINERECEIPT: "MARINERECEIPT",
  MARINERECEIPTSTATUSTIME: "MARINERECEIPTSTATUSTIME",
  MARINERECEIPTCOMPARTMENT: "MARINERECEIPTCOMPARTMENT",
  MARINERECEIPTCOMPARTMENTDETAIL: "MARINERECEIPTCOMPARTMENTDETAIL",
  MARINERECEIPTTRANSACTIONS: "MARINERECEIPTTRANSACTIONS",
  RAILWAGON: "RAILWAGON",
  RAILWAGONCOMPARTMENT: "RAILWAGONCOMPARTMENT",
  RAILDISPATCHPLAN: "RAILDISPATCHPLAN",
  RAILDISPATCHITEM: "RAILDISPATCHITEM",
  RAILDISPATCHWAGON: "RAILDISPATCHWAGON",
  RAILDISPATCHWAGONCOMPARTMENT: "RAILDISPATCHWAGONCOMPARTMENT",
  RAILDISPATCHWAGONDETAILPLAN: "RAILDISPATCHWAGONDETAILPLAN",
  RAILDISPATCHWAGONWEIGHBRIDGE: "RAILDISPATCHWAGONWEIGHBRIDGE",
  RAILDISPATCHSTATUSCHANGE: "RAILDISPATCHSTATUSCHANGE",
  RAILDISPATCHTRANSACTIONS: "RAILDISPATCHTRANSACTIONS",
  RAILLOADINGDETAILSFP: "RAILLOADINGDETAILSFP",
  RAILLOADINGDETAILSBP: "RAILLOADINGDETAILSBP",
  RAILLOADINGDETAILSADDITIVE: "RAILLOADINGDETAILSADDITIVE",
  RAILUNLOADINGDETAILSFP: "RAILUNLOADINGDETAILSFP",
  RAILUNLOADINGDETAILSBP: "RAILUNLOADINGDETAILSBP",
  RAILRECEIPTPLAN: "RAILRECEIPTPLAN",
  RAILRECEIPTITEM: "RAILRECEIPTITEM",
  RAILRECEIPTWAGON: "RAILRECEIPTWAGON",
  RAILRECEIPTWAGONCOMPARTMENT: "RAILRECEIPTWAGONCOMPARTMENT",
  RAILRECEIPTWAGONDETAILPLAN: "RAILRECEIPTWAGONDETAILPLAN",
  RAILRECEIPTWAGONWEIGHBRIDGE: "RAILRECEIPTWAGONWEIGHBRIDGE",
  RAILRECEIPTSTATUSCHANGE: "RAILRECEIPTSTATUSCHANGE",
  RAILRECEIPTRANSACTIONS: "RAILRECEIPTRANSACTIONS",
  RAILROUTE: "RAILROUTE",
  TANK_SHAREHOLDER: "TANK_SHAREHOLDER",
  SHAREHOLDERAGREEMENT: "SHAREHOLDERAGREEMENT",
  EXCHANGEAGEREEMENTITEM: "EXCHANGEAGEREEMENTITEM",
  MARINE_VESSEL_TRAILER: "MARINE_VESSEL_TRAILER",
  RAILUNLOADINGDETAILSADDITIVE: "RAILUNLOADINGDETAILSADDITIVE",
  CUSTOMERINVENTORY: "CUSTOMERINVENTORY",
  CUSTOMERAGREEMENT: "CUSTOMERAGREEMENT",
  CUSTOMERINVENTORYDISPATCHDETAILS: "CUSTOMERINVENTORYDISPATCHDETAILS",
  CUSTOMERINVENTORYRECEIPTDETAILS: "CUSTOMERINVENTORYRECEIPTDETAILS",
  LOADID: "LOADID",
  LOADID_PRODUCTLISTITEM: "LOADID_PRODUCTLISTITEM",
  TERMINAL: "TERMINAL",
};

export const AttributeTypes = {
  INT: "Int",
  LONG: "Long",
  FLOAT: "Float",
  DOUBLE: "Double",
};
export const ViewAllTruckReceiptOperations = {
  ViewReceipt_AuthorizeUnLoad: "ViewReceipt_AuthorizeUnLoad",
  ViewReceipt_AllowUnLoad: "ViewReceipt_AllowUnLoad",
  ViewReceipt_RecordWeight: "ViewReceipt_RecordWeight",
  ViewReceipt_CloseReceipt: "ViewReceipt_CloseReceipt",
  ViewReceipt_ViewAuditTrail: "ViewReceipt_ViewAuditTrail",
  ViewReceiptList_ViewTransactions: "ViewReceiptList_ViewTransactions",
  ViewReceipt_PrintRAN: "ViewReceipt_PrintRAN",
  ViewReceipt_ViewUnloading: "ViewReceipt_ViewUnloading",
  ViewReceipt_ManualEntry: "ViewReceipt_ManualEntry",
  ViewReceipt_PrintBOD: "ViewReceipt_PrintBOD",
  ViewReceipt_SendBOD: "ViewReceipt_SendBOD",
  ViewReceipt_ViewBOD: "ViewReceipt_ViewBOD",
  viewReceipt_BSIOutbound: "ViewReceipt_BSIOutbound",
};
export const TerminalWorkingTimeErrors = {
  WeekEndTime: "WeekEndTime",
  HolidayTime: "HolidayTime",
};

export const GeneralTMUserType = {
  Captain: "Captain",
  StaffVisitor: "StaffVisitor",
};

export const TMReports = "TMReports";

export const TMReportArchive = "TMReportsArchive";

export const WebPortalRoles = {
  CARRIERCOMPANY: "CarrierCompany",
  CUSTOMER: "Customer",
  SHAREHOLDER: "Shareholder",
  SUPPLIER: "Supplier",
};

export const DeviceModels = {
  AcculoadIII: "Accuload-III",
  AcculoadIV: "Accuload-IV",
  ContrecBI: "Contrec1010BI",
  ContrecBJ: "Contrec1010BJ",
  ContrecCB: "Contrec1010CB",
  ContrecCJ: "Contrec1010CJ",
  DanLoad: "DanLoad8000",
  MSCL: "MSC-L",
  Multiload: "Multiload-II",
  VirtualPreset: "VirtualPreset",
  Nedap: "NEDAP",
  StarII: "STAR_II",
  SIMATIC_BARCODE: "SIMATIC_BARCODE",
  MORPHO_BIOMETRIC: "MORPHO_BIOMETRIC",
  DEUContrec1030: "Contrec1030",
  RCUII: "RCU-II",
  TouchScreen: "TouchScreen",
  None: "NONE"
}

export const ShipmentActivityInfo = {
  BSI_SYNC_LOADINGDETAILS_AT: "BSI_SYNC_LOADINGDETAILS_AT",
  BSI_SYNC_MARINELOADINGDETAILS_AT: "BSI_SYNC_MARINELOADINGDETAILS_AT",
  BSI_SYNC_MARINEUNLOADINGDETAILS_AT: "BSI_SYNC_MARINEUNLOADINGDETAILS_AT",
  BSI_SYNC_RAILLOADINGDETAILS_AT: "BSI_SYNC_RAILLOADINGDETAILS_AT",
  CONTRACT_ALLOW_FORCE_CLOSE: "CONTRACT_ALLOW_FORCE_CLOSE",
  CONTRACT_ALLOW_UPDATE_DELETE: "CONTRACT_ALLOW_UPDATE_DELETE",
  HOME_PAGE_DISPLAY: "HOME_PAGE_DISPLAY",
  MANUAL_ENTRY_ALLOW: "MANUAL_ENTRY_ALLOW",
  MARINE_SHIPMENT_DISABLE_ADJUST_PLANNED_QUANTITY: "MARINE_SHIPMENT_DISABLE_ADJUST_PLANNED_QUANTITY",
  MARINE_SHIPMENT_DISABLE_COMPARTMENT_FORCE_CLOSE: "MARINE_SHIPMENT_DISABLE_COMPARTMENT_FORCE_CLOSE",
  MARINE_SHIPMENT_DISABLE_FORCE_CLOSE: "MARINE_SHIPMENT_DISABLE_FORCE_CLOSE",
  MARINE_SHIPMENT_ENABLE_AUTHORIZE_TO_LOAD: "MARINE_SHIPMENT_ENABLE_AUTHORIZE_TO_LOAD",
  MARINE_SHIPMENT_ENABLE_BOL_PRINT: "MARINE_SHIPMENT_ENABLE_BOL_PRINT",
  MARINE_SHIPMENT_ENABLE_DELETE: "MARINE_SHIPMENT_ENABLE_DELETE",
  MARINE_SHIPMENT_ENABLE_FAN_PRINT: "MARINE_SHIPMENT_ENABLE_FAN_PRINT",
  MARINE_SHIPMENT_ENABLE_MANUAL_ENTRY: "MARINE_SHIPMENT_ENABLE_MANUAL_ENTRY",
  MARINE_SHIPMENT_ENABLE_UPDATE: "MARINE_SHIPMENT_ENABLE_UPDATE",
  MARINE_SHIPMENT_MARK_TRANSACTION_INVALID: "MARINE_SHIPMENT_MARK_TRANSACTION_INVALID",
  ORDER_ALLOW_FORCE_CLOSE: "ORDER_ALLOW_FORCE_CLOSE",
  ORDER_ALLOW_UPDATE_DELETE: "ORDER_ALLOW_UPDATE_DELETE",
  PIPELINE_DISPATCH_ENABLE_DELETE: "PIPELINE_DISPATCH_ENABLE_DELETE",
  PIPELINE_DISPATCH_ENABLE_UPDATE: "PIPELINE_DISPATCH_ENABLE_UPDATE",
  PIPELINE_RECEIPT_ENABLE_UPDATE: "PIPELINE_RECEIPT_ENABLE_UPDATE",
  PIPELINE_RECEIPT_ENABLE_DELETE: "PIPELINE_RECEIPT_ENABLE_DELETE",
  RAILDISPATCH_DISABLE_ADJUSTPLANQTY: "RAILDISPATCH_DISABLE_ADJUSTPLANQTY",
  RAILDISPATCH_DISABLE_FORCECLOSE: "RAILDISPATCH_DISABLE_FORCECLOSE",
  RAILDISPATCH_DISABLE_WAGON_FORCECLOSE: "RAILDISPATCH_DISABLE_WAGON_FORCECLOSE",
  RAILDISPATCH_ENABLE_AUTHORIZEPLAN: "RAILDISPATCH_ENABLE_AUTHORIZEPLAN",
  RAILDISPATCH_ENABLE_BOLPRINT: "RAILDISPATCH_ENABLE_BOLPRINT",
  RAILDISPATCH_ENABLE_DELETEPLAN: "RAILDISPATCH_ENABLE_DELETEPLAN",
  RAILDISPATCH_ENABLE_FANPRINT: "RAILDISPATCH_ENABLE_FANPRINT",
  RAILDISPATCH_ENABLE_FORCECLOSE: "RAILDISPATCH_ENABLE_FORCECLOSE",
  RAILDISPATCH_ENABLE_LOADSPOTASSIGN: "RAILDISPATCH_ENABLE_LOADSPOTASSIGN",
  RAILDISPATCH_ENABLE_MANUALENTRY: "RAILDISPATCH_ENABLE_MANUALENTRY",
  RAILDISPATCH_ENABLE_PRODUCTASSIGN: "RAILDISPATCH_ENABLE_PRODUCTASSIGN",
  RAILDISPATCH_ENABLE_RECORDWEIGHT: "RAILDISPATCH_ENABLE_RECORDWEIGHT",
  RAILDISPATCH_ENABLE_SEQUENCING: "RAILDISPATCH_ENABLE_SEQUENCING",
  RAILDISPATCH_ENABLE_UPDATEPLAN: "RAILDISPATCH_ENABLE_UPDATEPLAN",
  SHIPMENT_DISABLE_ADJUST_PLANNED_QUANTITY: "SHIPMENT_DISABLE_ADJUST_PLANNED_QUANTITY",
  SHIPMENT_DISABLE_ADJUST_QUANTITY: "SHIPMENT_DISABLE_ADJUST_QUANTITY",
  SHIPMENT_DISABLE_COMPARTMENT_FORCE_CLOSE: "SHIPMENT_DISABLE_COMPARTMENT_FORCE_CLOSE",
  SHIPMENT_DISABLE_FORCE_CLOSE: "SHIPMENT_DISABLE_FORCE_CLOSE",
  SHIPMENT_DISABLE_LOADING_DETAILS_GRID: "SHIPMENT_DISABLE_LOADING_DETAILS_GRID",
  SHIPMENT_ENABLE_ALLOW_TO_LOAD: "SHIPMENT_ENABLE_ALLOW_TO_LOAD",
  SHIPMENT_ENABLE_AUTHORIZE_TO_LOAD: "SHIPMENT_ENABLE_AUTHORIZE_TO_LOAD",
  SHIPMENT_ENABLE_BOL_PRINT: "SHIPMENT_ENABLE_BOL_PRINT",
  SHIPMENT_ENABLE_DELETE: "SHIPMENT_ENABLE_DELETE",
  SHIPMENT_ENABLE_FAN_PRINT: "SHIPMENT_ENABLE_FAN_PRINT",
  SHIPMENT_ENABLE_RECORDWEIGHT: "SHIPMENT_ENABLE_RECORDWEIGHT",
  SHIPMENT_ENABLE_UPDATE: "SHIPMENT_ENABLE_UPDATE",
  SHIPMENT_UPDATE_EMPTY_COMPARTMENT: "SHIPMENT_UPDATE_EMPTY_COMPARTMENT",
  SHIPMENT_UPDATE_ENTRY_TIME: "SHIPMENT_UPDATE_ENTRY_TIME",
  SHIPMENTBONDNUMBER_UPDATE_ALLOW: "SHIPMENTBONDNUMBER_UPDATE_ALLOW",
  TM_PUBLISH_EVENT: "TM_PUBLISH_EVENT",
  TOPUP_ALLOW: "TOPUP_ALLOW",
  BAY_ALLOCATION_ALLOW: "BAY_ALLOCATION_ALLOW",
  BAY_ALLOCATION_FROM_STATE: "BAY_ALLOCATION_FROM_STATE",
  BAY_DEALLOCATION_ALLOW: "BAY_DEALLOCATION_ALLOW",
  BAY_DEALLOCATION_FROM_STATE: "BAY_DEALLOCATION_FROM_STATE",
  BAY_DEALLOCATION_TO_STATE: "BAY_DEALLOCATION_TO_STATE",
  BAY_ALLOCATION_TO_STATE: "BAY_ALLOCATION_TO_STATE",
  WF_STATUS_AFTER_AUTHORIZE_TO_LOAD: "WF_STATUS_AFTER_AUTHORIZE_TO_LOAD",
  WF_STATUS_AFTER_LADEN_WEIGHT: "WF_STATUS_AFTER_LADEN_WEIGHT",
  WF_STATUS_AFTER_TARE_WEIGHT: "WF_STATUS_AFTER_TARE_WEIGHT",
  SHIPMENT_STATE_AFTER_ALL_COMPARTMENT_FILLED: "SHIPMENT_STATE_AFTER_ALL_COMPARTMENT_FILLED",
  SHIPMENT_STATE_AFTER_ALLOW_TO_LOAD: "SHIPMENT_STATE_AFTER_ALLOW_TO_LOAD",
  SHIPMENT_STATE_AFTER_ANY_COMPARTMENT_PART_FILLED: "SHIPMENT_STATE_AFTER_ANY_COMPARTMENT_PART_FILLED",
  SHIPMENT_STATE_AFTER_EMPTY_COMPARTMENT: "SHIPMENT_STATE_AFTER_EMPTY_COMPARTMENT",
  SHIPMENT_STATE_AFTER_FORCE_CLOSE: "SHIPMENT_STATE_AFTER_FORCE_CLOSE",
  SHIPMENT_STATE_AFTER_TOPUP_APPROVAL: "SHIPMENT_STATE_AFTER_TOPUP_APPROVAL",
  RAILDISPATCH_STATE_ASSIGNLOADSPOT: "RAILDISPATCH_STATE_ASSIGNLOADSPOT",
  RAILDISPATCH_STATE_ASSIGNWAGON: "RAILDISPATCH_STATE_ASSIGNWAGON",
  RAILDISPATCH_STATE_AUTHORIZEPLAN: "RAILDISPATCH_STATE_AUTHORIZEPLAN",
  RAILDISPATCH_STATE_FORCECLOSE: "RAILDISPATCH_STATE_FORCECLOSE",
  MARINE_SHIPMENT_STATE_AFTER_FORCE_CLOSE: "MARINE_SHIPMENT_STATE_AFTER_FORCE_CLOSE",
  MARINE_WF_STATUS_AFTER_AUTHORIZE_TO_LOAD: "MARINE_WF_STATUS_AFTER_AUTHORIZE_TO_LOAD",
}

export const ActionTypeCode = {
  CHECK: "CHECK",
  UPDATE: "UPDATE"
}

export const ReceiptActivityInfo = {
  RAIL_RECEIPT_ENABLE_BOD_PRINT: "RAIL_RECEIPT_ENABLE_BOD_PRINT",
  RAIL_RECEIPT_ENABLE_DELETE: "RAIL_RECEIPT_ENABLE_DELETE",
  RAIL_RECEIPT_ENABLE_RAN_PRINT: "RAIL_RECEIPT_ENABLE_RAN_PRINT",
  RAIL_RECEIPT_ENABLE_UPDATE: "RAIL_RECEIPT_ENABLE_UPDATE",
  RAILRECEIPT_DISABLE_ADJUSTPLANQTY: "RAILRECEIPT_DISABLE_ADJUSTPLANQTY",
  RAILRECEIPT_DISABLE_FORCECLOSE: "RAILRECEIPT_DISABLE_FORCECLOSE",
  RAILRECEIPT_DISABLE_WAGON_FORCECLOSE: "RAILRECEIPT_DISABLE_WAGON_FORCECLOSE",
  RAILRECEIPT_ENABLE_AUTHORIZEPLAN: "RAILRECEIPT_ENABLE_AUTHORIZEPLAN",
  RAILRECEIPT_ENABLE_BODPRINT: "RAILRECEIPT_ENABLE_BODPRINT",
  RAILRECEIPT_ENABLE_CUSTOMERINVENTORYPLAN: "RAILRECEIPT_ENABLE_CUSTOMERINVENTORYPLAN",
  RAILRECEIPT_ENABLE_FORCECLOSE: "RAILRECEIPT_ENABLE_FORCECLOSE",
  RAILRECEIPT_ENABLE_LOADSPOTASSIGN: "RAILRECEIPT_ENABLE_LOADSPOTASSIGN",
  RAILRECEIPT_ENABLE_MANUALENTRY: "RAILRECEIPT_ENABLE_MANUALENTRY",
  RAILRECEIPT_ENABLE_RECORDWEIGHT: "RAILRECEIPT_ENABLE_RECORDWEIGHT",
  RAILRECEIPT_ENABLE_SEQUENCING: "RAILRECEIPT_ENABLE_SEQUENCING",
  RAILRECEIPT_ENABLE_UANPRINT: "RAILRECEIPT_ENABLE_UANPRINT",
  RAILRECEIPT_ENABLE_UPDATEPLAN: "RAILRECEIPT_ENABLE_UPDATEPLAN",
  BSI_SYNC_UNLOADINGDETAILS_AT: "BSI_SYNC_UNLOADINGDETAILS_AT",
  MARINE_RECEIPT_DISABLE_ADJUST_PLANNED_QUANTITY: "MARINE_RECEIPT_DISABLE_ADJUST_PLANNED_QUANTITY",
  MARINE_RECEIPT_DISABLE_COMPARTMENT_FORCE_CLOSE: "MARINE_RECEIPT_DISABLE_COMPARTMENT_FORCE_CLOSE",
  MARINE_RECEIPT_DISABLE_FORCE_CLOSE: "MARINE_RECEIPT_DISABLE_FORCE_CLOSE",
  MARINE_RECEIPT_ENABLE_AUTHORIZE_TO_UNLOAD: "MARINE_RECEIPT_ENABLE_AUTHORIZE_TO_UNLOAD",
  MARINE_RECEIPT_ENABLE_BOD_PRINT: "MARINE_RECEIPT_ENABLE_BOD_PRINT",
  MARINE_RECEIPT_ENABLE_CUSTOMERINVENTORYPLAN: "MARINE_RECEIPT_ENABLE_CUSTOMERINVENTORYPLAN",
  MARINE_RECEIPT_ENABLE_DELETE: "MARINE_RECEIPT_ENABLE_DELETE",
  MARINE_RECEIPT_ENABLE_MANUAL_ENTRY: "MARINE_RECEIPT_ENABLE_MANUAL_ENTRY",
  MARINE_RECEIPT_ENABLE_RAN_PRINT: "MARINE_RECEIPT_ENABLE_RAN_PRINT",
  MARINE_RECEIPT_ENABLE_UPDATE: "MARINE_RECEIPT_ENABLE_UPDATE",
  MARINE_RECEIPT_MARK_TRANSACTION_INVALID: "MARINE_RECEIPT_MARK_TRANSACTION_INVALID",
  RECEIPT_DISABLE_ADJUST_PLANNED_QUANTITY: "RECEIPT_DISABLE_ADJUST_PLANNED_QUANTITY",
  RECEIPT_DISABLE_COMPARTMENT_FORCE_CLOSE: "RECEIPT_DISABLE_COMPARTMENT_FORCE_CLOSE",
  RECEIPT_DISABLE_FORCE_CLOSE: "RECEIPT_DISABLE_FORCE_CLOSE",
  RECEIPT_ENABLE_ALLOW_TO_UNLOAD: "RECEIPT_ENABLE_ALLOW_TO_UNLOAD",
  RECEIPT_ENABLE_AUTHORIZE_TO_UNLOAD: "RECEIPT_ENABLE_AUTHORIZE_TO_UNLOAD",
  RECEIPT_ENABLE_BOU_PRINT: "RECEIPT_ENABLE_BOU_PRINT",
  RECEIPT_ENABLE_CUSTOMERINVENTORYPLAN: "RECEIPT_ENABLE_CUSTOMERINVENTORYPLAN",
  RECEIPT_ENABLE_DELETE: "RECEIPT_ENABLE_DELETE",
  RECEIPT_ENABLE_MANUAL_ENTRY: "RECEIPT_ENABLE_MANUAL_ENTRY",
  RECEIPT_ENABLE_RECORDWEIGHT: "RECEIPT_ENABLE_RECORDWEIGHT",
  RECEIPT_ENABLE_UAN_PRINT: "RECEIPT_ENABLE_UAN_PRINT",
  RECEIPT_ENABLE_UPDATE: "RECEIPT_ENABLE_UPDATE",
  RECEIPT_HOME_PAGE_DISPLAY: "RECEIPT_HOME_PAGE_DISPLAY",
  BAY_DEALLOCATION_ALLOW: "BAY_DEALLOCATION_ALLOW",
  BAY_DEALLOCATION_FROM_STATE: "BAY_DEALLOCATION_FROM_STATE",
  BAY_DEALLOCATION_RECEIPT_ALLOW: "BAY_DEALLOCATION_RECEIPT_ALLOW",
  BAY_DEALLOCATION_RECEIPT_FROM_STATE: "BAY_DEALLOCATION_RECEIPT_FROM_STATE",
  BAY_ALLOCATION_RECEIPT_ALLOW: "BAY_ALLOCATION_RECEIPT_ALLOW",
  BAY_ALLOCATION_RECEIPT_FROM_STATE: "BAY_ALLOCATION_RECEIPT_FROM_STATE",
  RECEIPTBONDNUMBER_UPDATE_ALLOW: "RECEIPTBONDNUMBER_UPDATE_ALLOW",
  TM_PUBLISH_EVENT: "TM_PUBLISH_EVENT",
  WF_STATUS_AFTER_AUTHORIZE_TO_UNLOAD: "WF_STATUS_AFTER_AUTHORIZE_TO_UNLOAD",
  BAY_ALLOCATION_RECEIPT_TO_STATE: "BAY_ALLOCATION_RECEIPT_TO_STATE",
  BAY_DEALLOCATION_RECEIPT_TO_STATE: "BAY_DEALLOCATION_RECEIPT_TO_STATE",
  BAY_DEALLOCATION_TO_STATE: "BAY_DEALLOCATION_TO_STATE",
  RECEIPT_STATE_AFTER_ALLOW_TO_UNLOAD: "RECEIPT_STATE_AFTER_ALLOW_TO_UNLOAD",
  RECEIPT_STATE_AFTER_FORCE_CLOSE: "RECEIPT_STATE_AFTER_FORCE_CLOSE",
  MARINE_RECEIPT_STATE_AFTER_FORCE_CLOSE: "MARINE_RECEIPT_STATE_AFTER_FORCE_CLOSE",
  MARINE_RECEIPT_WF_STATUS_AFTER_AUTHORIZE_TO_UNLOAD: "MARINE_RECEIPT_WF_STATUS_AFTER_AUTHORIZE_TO_UNLOAD",
  RAILRECEIPT_STATE_AUTHORIZEPLAN: "RAILRECEIPT_STATE_AUTHORIZEPLAN",
  RAILRECEIPT_STATE_FORCECLOSE: "RAILRECEIPT_STATE_FORCECLOSE"
}

export const PipeLineType = {
  DISPATCH: "DISPATCH",
  RECEIPT: "RECEIPT"
}

export const PipelineReceiptStatus = {
  AUTHORIZED: 1,
  AUTO_COMPLETED: 6,
  CLOSED: 9,
  EXCESSIVELY_COMPLETED: 7,
  INITIATED: 2,
  INPROGRESS: 3,
  INTERRUPTED: 4,
  MANUALLY_COMPLETED: 8,
  PARTIALLY_COMPLETED: 5,
  READY: 0
};
export const PipelineReceiptstatus = {
  CLOSED: "CLOSED",
  MANUALLY_COMPLETED: "MANUALLY COMPLETED",
  INPROGRESS: "IN PROGRESS",
  AUTHORIZED: "AUTHORIZED",
  READY: "READY",
  EXCESSIVELY_COMPLETED: "Excessively Completed",
  INITIATED: "Initiated",
  INTERRUPTED: "INTERRUPTED",
};

export const PipelineDispatchStatuses = {
  READY: "READY",
  CLOSED: "CLOSED",
  AUTHORIZED: "AUTHORIZED",
  INITIATED: "INITIATED",
  INPROGRESS: "INPROGRESS",
  INTERRUPTED: "INTERRUPTED",
  AUTO_COMPLETED: "AUTO_COMPLETED",
  PARTIALLY_COMPLETED: "PARTIALLY_COMPLETED",
  MANUALLY_COMPLETED: "MANUALLY_COMPLETED",
  EXCESSIVELY_COMPLETED: "EXCESSIVELY_COMPLETED"
}

export const PipelineDispatchAuditTrailStatuses = {
  READY: "READY",
  CLOSED: "CLOSED",
  AUTHORIZED: "AUTHORIZED",
  INITIATED: "INITIATED",
  INPROGRESS: "IN PROGRESS",
  INTERRUPTED: "INTERRUPTED",
  AUTO_COMPLETED: "AUTOMATICALLY COMPLETED",
  PARTIALLY_COMPLETED: "PARTIALLY COMPLETED",
  MANUALLY_COMPLETED: "MANUALLY COMPLETED",
  EXCESSIVELY_COMPLETED: "EXCESSIVELY COMPLETED"
}

export const ViewAllPipelineDispatchOperations = {
  Authorize_ManualEntry_Update: "Authorize_ManualEntry_Update",
  Authorize_Scada_Update: "Authorize_Scada_Update",
  PipelineDispatchList_btnTransactionReport: "PipelineDispatchList_btnTransactionReport",
  PipelineDispatchList_btnViewTransactionReport: "PipelineDispatchList_btnViewTransactionReport",
  PipelineDispatch_BtnAuditTrail: "PipelineDispatch_BtnAuditTrail",
  PipelineDispatch_BtnManualEntry: "PipelineDispatch_BtnManualEntry",
  PipelineDispatch_BtnClosed: "PipelineDispatch_BtnClosed",
  PipelineDispatch_BtnSubmit: "PipelineDispatch_BtnSubmit"
}


export const PipelineDispatchStatus = {
  AUTHORIZED: 1,
  AUTO_COMPLETED: 6,
  CLOSED: 9,
  EXCESSIVELY_COMPLETED: 7,
  INITIATED: 2,
  INPROGRESS: 3,
  INTERRUPTED: 4,
  MANUALLY_COMPLETED: 8,
  PARTIALLY_COMPLETED: 5,
  READY: 0
};
export const Pipeline_Dispatch_Status = {
  AUTHORIZED: "Authorized",
  AUTO_COMPLETED: "Automatically Completed",
  CLOSED: "Closed",
  EXCESSIVELY_COMPLETED: "Excessively Completed",
  INITIATED: "Initiated",
  INPROGRESS: "In Progress",
  INTERRUPTED: "Interrupted",
  MANUALLY_COMPLETED: "Manually Completed",
  PARTIALLY_COMPLETED: "Partially Completed",
  READY: "Ready"
};

export const AllocationEntityType = {
  SHAREHOLDER: "SHAREHOLDER",
  CARRIERCOMPANY: "CARRIERCOMPANY",
  CUSTOMER: "CUSTOMER",
  CUSTOMER_AND_SHAREHOLDER: "CUSTOMER_AND_SHAREHOLDER"
}

export const PAActiveStatuses = [
  {
    text: "Active",
    value: "Active",
  },
  {
    text: "Inactive",
    value: "Inactive",
  }
];
export const NotificationMessage = {
  "Experion": 1,
  "TM Database": 2,
  "Experion & TM Database": 3,
}

export const BayQueuePositionType = {
  Start: 0,
  End: 1,
  MoveNext: 2,
  MovePrevious: 3
}

export const ChartType = {
  SingleValue: "SingleValue",
  EntityDetails: "EntityDetails",
  FlexPie: "FlexPie",
  FlexSeries: "FlexSeries",
  FlexDynamicSeries: "FlexDynamicSeries",
  Bullet: "Bullet",
  Gauge: "Gauge"
}

export const TRANTYPE_CUSTOMERTRANSFER = "CUSTOMER_TRANSFER";
export const COREADMIN = "COREADMIN";
export const AgrementType = {
  EXCHANGE_AGREEMENT: "EXCHANGE_AGREEMENT",
  PRODUCT_TRANSFER_AGREEMENT:"PRODUCT_TRANSFER_AGREEMENT"
}
export const ShareholderAgreementStatus ={
  SUBMITTED:"SUBMITTED",
  REQUEST_REJECTED: "REQUEST_REJECTED",
  ACCEPTED: "ACCEPTED",
  OFFER_REJECTED: "OFFER_REJECTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  SUSPENDED:"SUSPENDED",
  FORCE_CLOSED:"FORCE_CLOSED",
  EXPIRED: "EXPIRED",
  DEACTIVATED:"DEACTIVATED"
}